<template>
  <ElPopover :visible="isOpen" popper-class="popover-project-selector" placement="right-start" :show-arrow="false" :offset="0" :width="widthPopover">
    <template #reference>
      <OnClickOutside @trigger="handleCancel">
        <div>
          <ElTooltip
            :content="systemStore.selectedProjectId!"
            placement="right"
            :disabled="isSidebarExpanded || isOpen"
            transition="none"
            :show-after="400"
            :hide-after="0"
          >
            <div
              class="project-selector focus-shadow-inset"
              role="button"
              tabindex="0"
              :class="{ isOpen, expanded: isSidebarExpanded }"
              @click="isOpen = !isOpen"
              @keypress.enter="isOpen = !isOpen"
            >
              <Avatar :name="systemStore.selectedProjectId!" />
              <span class="project-name bold-md ellipsis">{{ systemStore.selectedProjectId }}</span>
              <ArrowDownSmallIcon :class="{ isOpen }" />
            </div>
          </ElTooltip>
        </div>
      </OnClickOutside>
    </template>

    <div class="selector">
      <div class="title regular-lg">
        <div class="icon-headline">
          <ProjectIcon />
          <span>{{ $t('navigation.SELECT_PROJECT') }}</span>
        </div>

        <Btn only tertiary :svg-icon="CloseIcon" @click="handleCancel" />
      </div>

      <span class="info">{{ $t('navigation.reloadProject') }}</span>

      <div class="selection">
        <ElTooltip
          v-for="projectId in authStore.availableProjectIds"
          :key="projectId"
          :content="$t('navigation.onlyProject')"
          :disabled="authStore.availableProjectIds.length !== 1"
        >
          <div
            class="selectable-item focus-shadow-inset"
            :class="{ isActive: systemStore.selectedProjectId === projectId }"
            role="button"
            tabindex="0"
            @click="handleSave(projectId)"
            @keypress.enter="handleSave(projectId)"
          >
            <span class="regular-md ellipsis">{{ projectId }}</span>
          </div>
        </ElTooltip>
      </div>
    </div>
  </ElPopover>
</template>

<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components';
import Avatar from '@/components/Avatar.vue';
import Btn from '@/components/Btn.vue';
import ArrowDownSmallIcon from '@/components/UI/Icons/ArrowDownSmall.vue';
import CloseIcon from '@/components/UI/Icons/Close.vue';
import ProjectIcon from '@/components/UI/Icons/Project.vue';
import { isSidebarExpanded } from '@/composables/useLayout';
import router from '@/router';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { metas } from '@/utils/navigation';

const authStore = useAuthStore();
const systemStore = useSystemStore();

const isOpen = ref(false);

// sync with css
const widthItem = 254; // --selectable-item-width
const padding = 20; // --popover-content-padding
const gap = 10; // --selectable-items-gap

const widthPopover = computed(() => {
  const len = authStore.availableProjectIds.length;
  const cols = len <= 4 ? 1 : 2;
  return padding * 2 + widthItem * cols + gap * (cols - 1);
});

const handleCancel = () => {
  isOpen.value = false;
};

const handleSave = (projectId: string) => {
  handleCancel();

  const routeName = router.currentRoute.value.name ?? '';

  if (metas[routeName]?.hasProjectDetails) {
    // route to parent, with new project
    router.push({
      name: router.currentRoute.value.meta.parent,
      params: { projectId: projectId },
    });
  } else if (metas[routeName]?.hasProjectScope) {
    // route to self, with new project
    router.push({
      name: routeName,
      params: { ...router.currentRoute.value.params, projectId: projectId },
      query: router.currentRoute.value.query,
    });
  } else {
    // route to self, with new project, no page reload
    systemStore.setProjectId(projectId);
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/theme/variables';

.project-selector {
  cursor: pointer;
  height: 70px;
  padding: 5px;
  color: var(--color-primary);

  display: grid;
  & > * {
    grid-area: 1/1;
    place-self: center;
  }

  &:hover,
  &.isOpen {
    background-color: var(--color-background-hover);
  }

  span {
    display: none;
  }

  svg {
    justify-self: end;

    &.isOpen {
      rotate: 180deg;
    }
  }

  &.expanded {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;

    padding: 10px 15px;

    span {
      display: initial;
    }

    svg {
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}

.selector {
  --selector-text-color: light-dark(var(--color-ebony), var(--color-background-light-original));

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;

  .title,
  .info {
    color: var(--selector-text-color);
  }

  .title {
    align-self: stretch;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;

    .icon-headline {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        scale: calc(32 / 24); /* target-size / icon-size */
      }
    }
  }

  .selection {
    align-self: stretch;

    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: var(--selectable-items-gap, 10px);

    width: 100%;

    .selectable-item {
      display: flex;
      align-items: center;
      gap: 10px;

      width: var(--selectable-item-width, 254px);
      height: 50px;
      padding: 0 15px;
      border-radius: 10px;
      border: 1px solid var(--color-background-light);

      &:hover,
      &.isActive {
        cursor: pointer;
        background-color: var(--color-background-light);
        color: var(--selector-text-color);
      }
    }
  }
}

:global(.popover-project-selector.el-popover.el-popper) {
  padding: var(--popover-content-padding, 20px);
  padding-top: 30px;

  border: none;
  border-radius: 20px;
  border-top-left-radius: 0;

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}
</style>
